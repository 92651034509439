import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import YouTubeIcon from '@mui/icons-material/YouTube'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import { GoogleLogin } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import { writeStorage, useLocalStorage } from '@rehooks/local-storage'
import useCookie from 'react-use-cookie'

import Button from '@mui/material/Button'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { useGlobalState } from '../../context/state'

import './styles.scss'

Modal.setAppElement('#app')

const description = 'This is a page where you can upload AMV and then later access it on library page.'
const title = 'AMV is Art | Upload AMV'

const someStructuredData = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: title,
  url: 'https://amv.art/'
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column'
  }
}

export const Upload = () => {
  const {
    addAMV,
    setShowSnackbar,
    selectAMV
  } = useGlobalState()

  const [animeTitle, setAnimeTitle] = useState('')
  const [song, setSong] = useState('')
  const [artist, setArtist] = useState('')
  const [youtubeViews, setYoutubeVeiws] = useState('')
  const [creator, setCreator] = useState('')
  const [youtubeUploadDate, setYoutubeUploadDate] = useState(new Date())
  const [value, setValue] = useState('1')
  const [youtubeURL, setYoutubeURL] = useState('')
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [jwt] = useLocalStorage('jwt')
  const [, setUserToken] = useCookie('jwt', '0')
  const navigate = useNavigate()

  const reset = () => {
    setAnimeTitle('')
    setSong('')
    setArtist('')
    setYoutubeVeiws('')
    setYoutubeUploadDate(new Date())
    setCreator('')
    setYoutubeURL('')
  }

  const uploadVideo = (e) => {
    setLoading(true)

    e.preventDefault()

    const videoFile = e.target.files[0]

    if (!videoFile) {
      alert('Please select a video file to upload.')
      return
    }

    const uuid = uuidv4()

    const formData = new FormData()
    formData.append('video', videoFile)
    formData.append('animeTitle', animeTitle.trim())
    formData.append('song', song.trim() || 'unknown song')
    formData.append('artist', artist.trim() || 'unknown artist')
    formData.append('youtubeViews', youtubeViews || 0)
    formData.append('youtubeUploadDate', youtubeUploadDate)
    formData.append('creator', creator.trim() || 'unknown creator')
    formData.append('uuid', uuid)

    axios.post(`${process.env.API_URL}/upload`, formData)
      .then(({ data: AMV }) => {
        addAMV(AMV)
        setShowSnackbar({ msg: 'Successfully uploaded AMV' })
        reset()
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setShowSnackbar({ error: true, msg: 'Error uploading AMV' })
        reset()
        setLoading(false)
      })
  }

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const uploadAMVFromYoutube = async () => {
    if (!jwt) {
      setIsModalOpen(true)
      return
    }
    setLoading(true)
    const search = Object.fromEntries(new URLSearchParams(youtubeURL.split('?')[1]))
    const data = {
      AMVId: search.v,
      song: song.trim(),
      animeTitle: animeTitle.trim(),
      artist: artist.trim()
    }
    try {
      const { data: AMV } = await axios.post(`${process.env.API_URL}/upload-amv-from-youtube`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      addAMV(AMV)
      setShowSnackbar({
        msg: <div style={{ display: 'flex', gap: 7, alignItems: 'center' }}>
          Successfully uploaded AMV
          <Button sx={{ color: 'white', borderColor: 'white!important' }} variant="outlined" onClick={() => { navigate(`/library/${AMV._id}`); selectAMV(AMV) }}>Open</Button>
        </div>
      })
    } catch (error) {
      console.error('Error:', error)
      setShowSnackbar({ error: true, msg: 'Error uploading AMV' })
    }
    reset()
    setLoading(false)
  }

  const handleLoginSuccess = async (credentialResponse) => {
    // const decoded = jwtDecode(userInfo)
    writeStorage('jwt', credentialResponse.credential)
    setUserToken(credentialResponse.credential)
    setIsModalOpen(false)
  }

  const handleLoginFailure = (error) => {
    console.error('Login Failed: ', error)
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSON.stringify(someStructuredData)}</script>
      </Helmet>
      {loading && (
        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && <div className='upload-container'>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={<div style={{ display: 'flex', alignContent: 'center', gap: 10 }}><span style={{ lineHeight: 1.7 }}>Upload By Youtube Link</span><YouTubeIcon stle={{ color: 'red' }} /></div>} value="1" />
              <Tab label="Upload from PC file" value="2" />
            </TabList>
          </Box>
        </TabContext>
        <div className='upload-file-fields'>
          {value === '1' && <TextField size='small' label="Youtube video URL" variant="outlined" onChange={e => setYoutubeURL(e.target.value)} value={youtubeURL} />}

          <p style={{ color: 'gray' }}>*No need to enter anime name, song or artist, I've added AI that will do it for you!!!</p>
          {value !== '1' && <TextField size='small' label="Anime Title" variant="outlined" onChange={e => setAnimeTitle(e.target.value)} value={animeTitle} />}
          {value !== '1' && <TextField size='small' label="Song" variant="outlined" onChange={e => setSong(e.target.value)} value={song} />}
          {value !== '1' && <TextField size='small' label="Artist" variant="outlined" onChange={e => setArtist(e.target.value)} value={artist} />}
          {value !== '1' && <TextField size='small' label="Creator" variant="outlined" onChange={e => setCreator(e.target.value)} value={creator} />}
          {value !== '1' && <TextField type='number' size='small' label="Views when last seen" variant="outlined" onChange={e => setYoutubeVeiws(e.target.value)} value={youtubeViews} />}
          {value !== '1' && <DatePicker label="Upload date from youtube" onChange={value => setYoutubeUploadDate(value)} value={youtubeUploadDate} />}
        </div>

        {
          value !== '1'
            ? (
                jwt
                  ? (
                      <Button variant="outlined" startIcon={<FileUploadIcon sx={{ fill: 'rgb(25, 118, 210)' }} />}>
                        Upload AMV
                        <input className='upload-file' type="file" accept="video/*" onChange={uploadVideo} />
                      </Button>
                    )
                  : (
                      <Button variant="outlined" startIcon={<FileUploadIcon sx={{ fill: 'rgb(25, 118, 210)' }} />} onClick={uploadAMVFromYoutube}>
                        Upload AMV
                      </Button>
                    )
              )
            : (
                <Button disabled={!youtubeURL} variant="outlined" startIcon={<FileUploadIcon sx={{ fill: youtubeURL ? 'rgb(25, 118, 210)' : 'gray' }} />} onClick={uploadAMVFromYoutube}>
                  Upload AMV
                </Button>
              )
        }
      </div>}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <div className='close'><h2>Login or Sign up</h2></div>
        <div style={{ marginBottom: 20 }}>
          <p style={{ marginBottom: 4 }}>To upload AMV you need to login so that we can show you only your AMVs inside library.</p>
        </div>
        <div className='modalContent' id='model-content'>
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onFailure={handleLoginFailure}
            cookiePolicy='single_host_origin'
            prompt_parent_id='tester-tester'
          />
        </div>
      </Modal>
    </>
  )
}
