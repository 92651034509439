import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { useGoogleOneTapLogin } from '@react-oauth/google'
import { writeStorage, useLocalStorage } from '@rehooks/local-storage'
import { jwtDecode } from 'jwt-decode'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/IconButton'
import { useDetectClickOutside } from 'react-detect-click-outside'
import useCookie from 'react-use-cookie'

import { Logo } from '../Logo'

import './styles.scss'

export const Navbar = () => {
  const location = useLocation()
  const [jwt] = useLocalStorage('jwt')
  const [showNav, setShowNav] = useState(false)
  const ref = useDetectClickOutside({ onTriggered: () => setShowNav(false) })
  const [, setUserToken] = useCookie('jwt', '0')

  const landing = location.pathname === '/'

  const jwtDecoded = jwt && jwtDecode(jwt)
  const jwtUpToDate = jwt && jwtDecoded.exp * 1000 > Date.now()

  // useGoogleOneTapLogin({
  //   disabled: Boolean(jwtUpToDate),
  //   auto_select: true,
  //   onSuccess: async tokenResponse => {
  //     writeStorage('jwt', tokenResponse.credential)
  //     setUserToken(tokenResponse.credential)
  //   }
  // })

  return (
    <div className={cn('navbar-container', { 'black-border': !landing })} ref={ref}>
      {landing ? null : <Logo />}
      <Button
        sx={{ padding: 0, height: 20, color: landing ? 'white' : 'rgba(0, 0, 0, 0.87)' }}
        className='navbar-button'
        disableRipple
        aria-label="navbar hamburger"
        onClick={() => setShowNav(!showNav)}
      >
        <MenuIcon />
      </Button>
      <nav className='mobile-navbar' style={{ background: landing ? 'rgba(0, 0, 0, 0.23)' : undefined, display: showNav ? 'flex' : 'none' }}>
        <NavLink onClick={() => setShowNav(false)} className={cn({ black: !landing })} to='/upload'>Upload now and never lose</NavLink>
        <NavLink onClick={() => setShowNav(false)} className={cn({ black: !landing })} to='/report-a-loss'>Report a Loss</NavLink>
        <NavLink onClick={() => setShowNav(false)} className={cn({ black: !landing })} to='/help-find'>Help find</NavLink>
        <NavLink onClick={() => setShowNav(false)} className={cn({ black: !landing })} to='/library'>Library</NavLink>
      </nav>

      <nav className='navbar'>
        <NavLink className={cn({ black: !landing })} to='/upload'>Upload now and never lose</NavLink>
        <NavLink className={cn({ black: !landing })} to='/report-a-loss'>Report a Loss</NavLink>
        <NavLink className={cn({ black: !landing })} to='/help-find'>Help find</NavLink>
        <NavLink className={cn({ black: !landing })} to='/library'>Library</NavLink>
      </nav>
    </div>
  )
}
