import React, { memo } from 'react'
import { Helmet } from 'react-helmet'

import ChromeIcon from './assets/chrome.svg?url'
import YouTubeIcon from '@mui/icons-material/YouTube'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import classNames from 'classnames'

import './styles.scss'

// eslint-disable-next-line react/display-name
const VideoComponent = memo(() => {
  return (
    // eslint-disable-next-line react/no-unknown-property
    <video className='landing-video' autoPlay muted controls={false} loop playsInline>
      <source
        src='https://storage.cloud.google.com/videos.amv.art/UchihaObitocruelworldAMVFullHD.mp4'
        type="video/mp4"
      />
    </video>
  )
}, () => true)

const description = 'Anime Video Clips. AMV is Art. And no one has a right to delete it.'

const extensionLink = 'https://chromewebstore.google.com/detail/backup-amv/dgkemofeiaikkccckninlcnpojilphod?hl=en&authuser=0'

export const Landing = () => {
  const someStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'AMV is Art',
    url: 'https://amv.art/'
  }
  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>Anime Video Clips</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSON.stringify(someStructuredData)}</script>
      </Helmet>
      <VideoComponent />
      <div className='main-text'>
        <div className='center' style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <h1>AMV is Art</h1>
          <small>and no one has a right to delete it</small>
          <div style={{ display: 'flex', gap: 12 }}>
            <button onClick={() => location.href = '/upload'} className='button-upload-youtube'>Upload<FileUploadIcon fontSize='11px' /></button>
            <button
              onClick={() => location.href = '/library'}
              className={classNames('button-upload-youtube', 'button-watch')}
              style={{ background: '#FFABAB', color: 'black' }}
            >
              Watch
              <YouTubeIcon />
            </button>
          </div>
        </div>
      </div>
      <a className='extension' href={extensionLink} target="_blank" rel="noreferrer">
        <span>Get extension</span>
        <img src={ChromeIcon} alt="chrome extension icon" />
      </a>
    </>
  )
}
