import React, { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { useLocalStorage } from '@rehooks/local-storage'
import { Helmet } from 'react-helmet'
import { useParams, useSearchParams } from 'react-router-dom'
import { Grid } from 'react-virtualized'
import { useWindowSize } from 'react-use'
import { Capacitor } from '@capacitor/core'
import { useMediaQuery } from 'react-responsive'
import { AnimatePresence } from 'framer-motion'
import { formatISODuration } from 'date-fns'

import { AMVView } from '../AMVView'
import { MainAreaHeader } from '../MainAreaHeader'
import { AMVItem } from '../AMVItem'
import { Drawer } from '../Drawer'

import { useAPI } from '../../context/useAPI'
import { useGlobalState } from '../../context/state'
import { useRenderDT } from '../../context/render-tree'

import './styles.scss'

let title = 'AMV is Art | AMV Library'
let description = 'AMV Library is where you have a list of all AMVs on this site. You can filter it, sort it, and obviously watch it.'

let someStructuredData = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: title,
  url: 'https://amv.art/'
}

function to2DMatrix (array, columns) {
  const matrix = []

  if (columns === 0) {
    return matrix
  }

  for (let i = 0; i < array.length; i += columns) {
    matrix.push(array.slice(i, i + columns))
  }

  return matrix
}

const isCapacitorNative = Capacitor.isNativePlatform()

export const MainArea = () => {
  const { originalName: id } = useParams()
  const [searchParams] = useSearchParams()
  const searchParamsObj = Object.fromEntries(searchParams)
  const api = useAPI()
  const [page, setPage] = useState(1)

  const {
    AMVs,
    showOnlyMyAMVs,
    appendAMVs,
    AMVFieldToFilterBy: filterField,
    AMVFieldValueToFilterBy: filterValue,
    AMVFieldToSortBy: sortField,
    sortingDirection: sortDirection,
    setAMVFieldValuesToFilterBy,
    setAMVs,
    selectedAMV
  } = useGlobalState()

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const { getFields } = useAPI()

  useEffect(() => {
    if (page === 1 && !isCapacitorNative) {
      return
    }
    const main = async () => {
      const { AMVs } = await api.fetchAMVs({ page, filterField, filterValue, sortField, sortDirection, showOnlyMyAMVs })
      appendAMVs(AMVs)
    }
    main()
  }, [page, showOnlyMyAMVs])

  useEffect(() => {
    const main = async () => {
      const { AMVs } = await api.fetchAMVs({ page: 1, filterField, filterValue, sortField, sortDirection, showOnlyMyAMVs })
      setAMVs(AMVs)
    }
    main()
  }, [filterValue, sortDirection, sortField, showOnlyMyAMVs])

  if ((searchParamsObj.fieldName === 'animeTitle' || filterField === 'animeTitle') && searchParamsObj.fieldValue) {
    title = `AMV is Art | ${searchParamsObj.fieldValue} AMV`
    someStructuredData = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: title,
      url: `https://amv.art/library?fieldValue=${searchParamsObj.fieldValue}`
    }
    description = `${searchParamsObj.fieldValue} AMVs, ${searchParamsObj.fieldValue} AMV, AMV of ${searchParamsObj.fieldValue}, watch`
  }

  const {
    renderDT: {
      AMVIsVisible,
      gridIsVisible
    },
    drawerIsOpen
  } = useRenderDT()

  useEffect(() => {
    if (filterField) {
      getFields({ fieldName: filterField, showOnlyMyAMVs })
        .then(({ values }) => {
          setAMVFieldValuesToFilterBy(values)
        })
    }
  }, [filterField, showOnlyMyAMVs])

  const { width, height } = useWindowSize()

  const widthWithDrawer = (width - (drawerIsOpen ? 300 : 0))
  const cardWidth = 270
  const numberOfCols = Math.floor(widthWithDrawer / cardWidth)
  const rowCount = Math.ceil(AMVs.length / numberOfCols)
  const matrix2d = to2DMatrix(AMVs, numberOfCols)

  function cellRenderer ({ columnIndex, key, rowIndex, style }) {
    const AMV = matrix2d[rowIndex]?.[columnIndex]
    if (!AMV) {
      return null
    }
    return (
      <div key={key} style={style}>
        <AMVItem key={AMV.id} {...AMV} />
      </div>
    )
  }

  const rootPage = !id

  if (process.env.SERVER && rootPage) {
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">
          {JSON.stringify(someStructuredData)}
        </script>
      </Helmet>
    )
  }

  const onScroll = ({ scrollHeight, scrollTop, clientHeight }) => {
    const loadMore = Math.ceil(scrollHeight - scrollTop) === clientHeight

    if (loadMore) {
      setPage(state => state + 1)
    }
  }

  const topBarHeight = width <= 685 ? 141 + 48 : 141

  if (AMVIsVisible.value) {
    description = `This is AMV that someone really wanted to preserve. It's for anime ${selectedAMV.animeTitle}. The song is ${selectedAMV.song} by ${selectedAMV.artist}. It's original name is ${selectedAMV.originalName}`
    title = selectedAMV.originalName
    someStructuredData = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      description,
      duration: formatISODuration({ seconds: parseInt(selectedAMV.duration) }),
      embedUrl: selectedAMV.url,
      interactionCount: selectedAMV.youtubeViews,
      name: selectedAMV.originalName,
      thumbnailUrl: [
        selectedAMV.imgUrl
      ],
      uploadDate: selectedAMV.youtubeUploadDate,
      genre: 'Art',
      author: selectedAMV.creator
    }
  }

  return (
    <div className="main-area">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {<script type="application/ld+json">
          {JSON.stringify(someStructuredData)}
        </script>}
      </Helmet>
      <MainAreaHeader />
      {AMVIsVisible.value ? <AMVView /> : null}
      {drawerIsOpen ? <Drawer className='main-area-drawer' /> : null}
      {
        (!isMobile && gridIsVisible.value) || (isMobile && gridIsVisible.value && !drawerIsOpen)
          ? (
              <AnimatePresence>
                <Grid
                  onScroll={onScroll}
                  style={{ display: 'flex', justifyContent: 'center' }}
                  cellRenderer={cellRenderer}
                  columnCount={numberOfCols}
                  columnWidth={cardWidth}
                  height={height - topBarHeight}
                  rowCount={rowCount}
                  rowHeight={285}
                  width={widthWithDrawer}
                  autoWidth
                />
              </AnimatePresence>
            )
          : null
      }
    </div>
  )
}
