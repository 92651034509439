import React from 'react'

import { useGlobalState } from '../../../../context/state'

export const AMVinfoView = () => {
  const { selectedAMV } = useGlobalState()

  return (
    <div className='amv-info'>
      <div>
        <b style={{ marginTop: 0 }}>AMV Title</b>
        <h1 className="dynamic-text">{selectedAMV.originalName}</h1>
      </div>
      <div>
        <b>Anime Title</b>
        <h2 className="dynamic-text">{selectedAMV.animeTitle}</h2>
      </div>
      <div>
        <b>Artist</b>
        <h3 className="dynamic-text">{selectedAMV.artist}</h3>
      </div>
      <div>
        <b>Song</b>
        <h3 className="dynamic-text">{selectedAMV.song}</h3>
      </div>
    </div>
  )
}
