import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Routes, Route } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ReactGA from 'react-ga4'

import { Navbar } from './components/Navbar'
import { Container } from './components/Container'
import { Upload } from './components/Upload'
import { ReportLoss } from './components/ReportLoss'
import { HelpFind } from './components/HelpFind'
import { Landing } from './components/Landing'

import { GlobalStateProvider } from './context/state'
import { RenderDTProvider } from './context/render-tree'
import { MixpanelProvider } from './context/mixpanel'

import './styles.scss'

const TRACKING_ID = 'G-X1JZ2M89YZ'

ReactGA.initialize(TRACKING_ID)

export const App = ({ AMVs, renderDT, selectedAMV, missingReports, jwtMock }) => {
  return (
    <MixpanelProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <GoogleOAuthProvider clientId="693824624560-foeccfbb2kbrdeo5potl6jrilj82t8h3.apps.googleusercontent.com">
          <div className="container">
            <GlobalStateProvider AMVs={AMVs} renderDT={renderDT} selectedAMV={selectedAMV} jwtMock={jwtMock}>
              <RenderDTProvider>
                <Navbar />
                <Routes>
                  <Route path="/" index element={<Landing />} />
                  <Route path="/library/:originalName?" element={<Container />} />
                  <Route path="/upload" element={<Upload />} />
                  <Route path="/help-find" element={<HelpFind missingReports={missingReports} jwtMock={jwtMock} />} />
                  <Route path="/report-a-loss" element={<ReportLoss />} />
                </Routes>
              </RenderDTProvider>
            </GlobalStateProvider>
          </div>
        </GoogleOAuthProvider>
      </LocalizationProvider>
    </MixpanelProvider>
  )
}
